import { SelectOptionType } from 'components/Select/types';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import { useCreditStatus } from 'hooks/creditStatus';
import { useCreditRequestRules } from 'hooks/rules/useCreditRequestRules';

import { useCreditRequestStore } from '../store/useCreditRequestStore';

export const useChangeStatus = () => {
  const { STATUS_CHANGE_RULES } = useCreditRequestRules();
  const { setShouldReloadAfterSuccess } = useCreditStatus();
  const { creditForm } = useCreditRequestContext();

  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const selectedSubDivisionId = useCreditRequestStore(state => state.selectedSubDivisionId);
  const teamStatusChangeTeamStatusCurrentId = useCreditRequestStore(state => state.teamStatusChangeTeamStatusCurrentId);
  const teamStatusChangeOperationCurrentId = useCreditRequestStore(state => state.teamStatusChangeOperationCurrentId);
  const teamStatusChangeApprovedValue = useCreditRequestStore(state => state.teamStatusChangeApprovedValue);
  const teamStatusChangeMoneyPrefix = useCreditRequestStore(state => state.teamStatusChangeMoneyPrefix);
  const teamStatusChangeCreditRequestNumber = useCreditRequestStore(state => state.teamStatusChangeCreditRequestNumber);
  const teamStatusChangeCreditRequestId = useCreditRequestStore(state => state.teamStatusChangeCreditRequestId);
  const teamStatusChangeRequestedAmount = useCreditRequestStore(state => state.teamStatusChangeRequestedAmount);
  const modalityId = useCreditRequestStore(state => state.modalityId);
  const setTeamStatusChangeTeamStatusCurrentId = useCreditRequestStore(
    state => state.setTeamStatusChangeTeamStatusCurrentId,
  );
  const setIsLoading = useCreditRequestStore(state => state.setIsLoading);
  const creditRequestData = useCreditRequestStore(state => state.creditRequestData);

  const handleChangeStatus = async (value: string | SelectOptionType[]) => {
    // Apenas valores unicos são aceitos
    if (Array.isArray(value)) return;
    setIsLoading(true);
    setShouldReloadAfterSuccess(true);

    await STATUS_CHANGE_RULES.tryChangeStatus({
      datasource: creditRequestData.datasource ?? '',
      operationId: teamStatusChangeOperationCurrentId,
      partialLimit: [],
      currentStatusId: teamStatusChangeTeamStatusCurrentId,
      approvedValue: Number(teamStatusChangeApprovedValue ?? 0),
      currencyTypeSlug: teamStatusChangeMoneyPrefix,
      creditRequestNumber: teamStatusChangeCreditRequestNumber,
      newStatusId: value,
      creditRequestId: teamStatusChangeCreditRequestId,
      netAmount: Number(creditForm.getFieldValue('json_data.net_amount') ?? '0'),
      requestedAmount: Number(teamStatusChangeRequestedAmount ?? 0),
      enabledValue: undefined,
      approvedValueBarter: Number(creditForm.getFieldValue('approved_value_barter') ?? 0),
      approvedValueCash: Number(creditForm.getFieldValue('approved_value_cash') ?? 0),
      enabledValueBarter: Number(creditForm.getFieldValue('enabled_value_cash') ?? 0),
      enabledValueCash: Number(creditForm.getFieldValue('enabled_value_cash') ?? 0),
      requestedAmountBarter: Number(creditForm.getFieldValue('requested_amount_barter') ?? 0),
      requestedAmountCash: Number(creditForm.getFieldValue('requested_amount_cash') ?? 0),
      modalityId,
      subDivisionId: selectedSubDivisionId,
      providerSapCode: creditForm.getFieldValue('sap_bp_headoffice_subsidiary_code'),
      isImported: !!creditRequestData.last_import?.description,
      limitExpirationDate: creditRequestData.limit_expiration_date,
    });

    if (!pageStatus.viewing) {
      creditForm.setFieldsValue({ team_status_id: value.toString() });
      setTeamStatusChangeTeamStatusCurrentId(value.toString());
    }

    setIsLoading(false);
  };
  return { handleChangeStatus };
};
