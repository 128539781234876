/* eslint-disable complexity */
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportModal } from '@agrodatabr/agrodataui';
import { ColumnDef } from '@tanstack/react-table';
import { ComboBox } from 'components/ui/combo';
import { DataTable } from 'components/ui/data-table';
import { Can } from 'hooks/ability';
import { useCreditRequestRules } from 'hooks/rules/useCreditRequestRules';
import { usePagination } from 'hooks/usePagination';
import { cn } from 'lib/utils';
import { useGetCreditRequestsQuery } from 'pages/CreditRequest/api/use-get-credit-requests-query';
import { Converter } from 'utils/Converter';

import DTOCreditPhase from '../../../../@types/dtos/credit-request/DTOCreditPhase';
import { IconWithTooltip } from '../../../../components';
import { SelectOptionType } from '../../../../components/Select/types';
import { getReportModalTranslation } from '../../../../componentsTranslation/reportModal';
import {
  PHYSICAL_PERSON_TYPE,
  OPERATION_SLUG_PREPAY,
  OPERATION_PREPAY_ID,
  CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE,
  CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_PREPAY,
  SERASA_STATUS_WITH_RESTRICTION,
} from '../../../../configs/constants';
import { useAuth } from '../../../../hooks/auth';
import { useCreditStatus } from '../../../../hooks/creditStatus';
import creditApi from '../../../../services/api/credit';
import documentApi from '../../../../services/api/document';
import { getTranslations } from '../../../../services/translations';
import CreditRequestData from '../../../../types/CreditRequest/CreditRequestData';
import TeamStatusData from '../../../../types/CreditRequest/DTOTeamStatus';
import { Formatter } from '../../../../utils/Formatter';
import renderComponent from '../../../../utils/RenderComponent';
import { useFilterListOfNextTeamStatus } from '../../hooks/useFilterListOfNextTeamStatus';
import { SerasaRestriction } from '../organisms/CreditKanban/Card/SerasaRestriction';

export interface ListViewProps {
  filterData?: {
    division: string;
    harvest: string;
    number: string;
    operation: string;
    search: string;
    subdivision: string;
  };
}

export interface CellStatusProps {
  status?: string;
}

export interface CreditRequestDataTable {
  page: number;
  per_page: number;
  sort?: string;
  direction?: string;
  filter?: string;
  search?: string;
  [key: string]: string | number | undefined;
}

const ListView: React.FC<ListViewProps> = ({ filterData }) => {
  const { t, i18n } = useTranslation();

  const { teams, user } = useAuth();
  const { getListOfNextTeamStatus, getTeamStatus, getPhase, teamStatusHasChanged, setTeamStatusHasChanged } =
    useCreditStatus();
  const { STATUS_CHANGE_RULES } = useCreditRequestRules();
  const { filterListOfNextTeamStatus } = useFilterListOfNextTeamStatus();

  const [creditReportId, setCreditReportId] = useState('');
  /** reports table */
  const [exporting, setExporting] = useState(false);

  const { dispatchPagination, paginationConfig } = usePagination();

  const getCreditRequestsQuery = useGetCreditRequestsQuery({
    ...paginationConfig,
    filter: filterData,
    dispatchPagination,
  });

  const handleChangeStatus = async (value: string | SelectOptionType[], row: CreditRequestData) => {
    // Apenas valores unicos são aceitos
    if (Array.isArray(value)) return;

    await STATUS_CHANGE_RULES.tryChangeStatus({
      datasource: row.datasource,
      operationId: row.operation_id,
      partialLimit: row.partial_limit,
      currentStatusId: row.team_status_id,
      approvedValue: Number(row.approved_value ?? 0),
      currencyTypeSlug: row.currency_type !== undefined && row.currency_type !== null ? row.currency_type.slug : 'USD',
      creditRequestNumber: row.number,
      newStatusId: value,
      creditRequestId: row.id,
      netAmount: Number(row.json_data?.net_amount ?? '0'),
      requestedAmount: Number(row.requested_amount ?? 0),
      enabledValue: row.enabled_value ?? 0,
      requestedAmountBarter: row.requested_amount_barter ?? 0,
      requestedAmountCash: row.requested_amount_cash ?? 0,
      approvedValueBarter: row.approved_value_barter ?? 0,
      approvedValueCash: row.approved_value_cash ?? 0,
      enabledValueBarter: row.enabled_value_barter ?? 0,
      enabledValueCash: row.enabled_value_cash ?? 0,
      modalityId: row.json_data?.modality_id ?? '',
      subDivisionId: row.sub_division_id ?? null,
      providerSapCode: row.requester_person?.sap_bp_subsidiary_code ?? '',
      limitExpirationDate: row.limit_expiration_date,
      isImported: !!row.last_import?.description,
    });

    getCreditRequestsQuery.refetch();
  };

  const getActions = (record: CreditRequestData) => (
    <>
      {record.operation?.slug !== OPERATION_SLUG_PREPAY && record.requester_person_type === PHYSICAL_PERSON_TYPE && (
        <Can I="credit.request.report.index">
          <Can I="credit.request.report.store">
            <IconWithTooltip
              action="attachment"
              title={t('pages.credit-request.table.icons.reports')}
              onClick={() => {
                setCreditReportId(record.id);
                setExporting(true);
              }}
            />
          </Can>
        </Can>
      )}

      {record.operation_id === OPERATION_PREPAY_ID &&
        CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE_PREPAY.includes(record.team_status.id) && (
          <IconWithTooltip
            action="edit"
            title={t('pages.credit-request.table.icons.edit')}
            onClick={() => {
              window.open(`/credit-request/view/${record.id}`, '_blank');
            }}
          />
        )}

      {renderComponent(
        record.team_status &&
          record.operation_id !== OPERATION_PREPAY_ID &&
          CREDIT_REQUEST_TEAM_STATUS_EDITABLE_RULE.includes(record.team_status.id),
        <IconWithTooltip
          action="edit"
          title={t('pages.credit-request.table.icons.edit')}
          onClick={() => {
            window.open(`/credit-request/edit/${record.id}`, '_blank');
          }}
        />,
        <IconWithTooltip
          action="view"
          title={t('pages.credit-request.table.icons.detail')}
          onClick={() => {
            window.open(`/credit-request/view/${record.id}`, '_blank');
          }}
        />,
      )}
    </>
  );

  const columns = useMemo(
    (): ColumnDef<CreditRequestData>[] => [
      {
        header: t('status'),
        accessorKey: 'status',
        meta: {
          body: {
            cell: { className: 'p-0' },
          },
        },
        cell: ({ row }) => {
          const request = row.original;
          const optionsStatus = getListOfNextTeamStatus(
            request.team_status_id,
            request.operation_id,
            user.position_id,
            true,
          ) as SelectOptionType[];

          const listNextTeamStatus = filterListOfNextTeamStatus({
            listOfTeamStatus: optionsStatus,
            team_status_id: request.team_status_id,
            datasource: request.datasource,
            has_assignees: request.has_assignees,
            approved_value: request.approved_value,
            operation: request.operation.id,
          });

          let canShowOptions = false;

          const teamStatus = getTeamStatus(request.team_status_id) as TeamStatusData;
          const teamStatusTitle = teamStatus && getTranslations(teamStatus, 'title', i18n.language, 'name');

          if (teamStatus) {
            const findUserTeam = teams.find(team => team.id === teamStatus.team_id);
            if (findUserTeam && listNextTeamStatus.length > 1) {
              canShowOptions = true;
            }
          }

          return (
            <div
              className={cn(`border-l-[10px] min-h-10 pr-2 gap-2 flex items-center pl-2 max-w-[400px] `)}
              style={{ borderColor: teamStatus.color ?? '' }}
            >
              {canShowOptions && (
                <ComboBox
                  showClearButton={false}
                  onValueChange={value => handleChangeStatus(value, request)}
                  options={Converter.selectOptionArray(listNextTeamStatus, 'value', 'value', 'label')}
                  value={request.team_status_id}
                />
              )}
              {!canShowOptions && <span className="w-full">{teamStatusTitle}</span>}
              <div className="h-5 flex items-center justify-center min-w-5 w-5 rounded-full bg-red-600 text-white text-xs">
                {request.status_count}
              </div>
            </div>
          );
        },
      },
      {
        header: t('phase'),
        accessorKey: 'phase',
        cell: ({ row }) => {
          const request = row.original;
          const teamStatus = getTeamStatus(request.team_status_id) as TeamStatusData;
          if (!teamStatus) return '';
          const phase = getPhase(teamStatus.phase_id) as DTOCreditPhase;
          return phase && getTranslations(phase, 'title', i18n.language, 'name');
        },
      },
      {
        header: t('request-number'),
        accessorKey: 'number',
      },
      {
        header: t('harvest'),
        accessorKey: 'harvest',
        cell: ({ row }) => {
          const request = row.original;
          return request.harvest?.name;
        },
      },
      {
        header: t('division'),
        accessorKey: 'division',
        cell: ({ row }) => {
          const request = row.original;
          return request.division?.name;
        },
      },
      {
        header: t('sub-division'),
        accessorKey: 'sub_division',
        cell: ({ row }) => {
          const request = row.original;
          return request.sub_division?.name;
        },
      },
      {
        header: t('operation'),
        accessorKey: 'operation',
        cell: ({ row }) => {
          const request = row.original;
          return <span className="whitespace-nowrap">{request.operation?.name}</span>;
        },
      },
      {
        header: t('regional'),
        accessorKey: 'region',
        cell: ({ row }) => {
          const request = row.original;
          return request.harvest?.name;
        },
      },
      {
        header: t('provider'),
        accessorKey: 'requester_person',
        cell: ({ row }) => {
          const request = row.original;
          const personTypeRowData = request.requester_person_type === PHYSICAL_PERSON_TYPE;
          const provider = personTypeRowData ? request.requester_person?.name : request.requester_person?.company_name;

          let name = provider || '-';
          if (request.operation.slug === 'prepayment') {
            name = request.json_data ? request.json_data?.contract?.requester_person_name : '-';
          }
          return <span className="whitespace-nowrap">{name}</span>;
        },
      },
      {
        header: t('serasa-restriction'),
        accessorKey: 'serasaRestriction',
        cell: ({ row }) => {
          const request = row.original;
          const restriction =
            request.requester_person?.serasa_counterparty?.serasa_counterparty_status_id ===
            SERASA_STATUS_WITH_RESTRICTION;
          const isPrepay = request.operation_id === OPERATION_PREPAY_ID;
          return !restriction && isPrepay ? (
            <SerasaRestriction
              fullSize
              serasaStatus={request.requester_person?.serasa_counterparty?.serasa_counterparty_status_id}
            />
          ) : (
            ''
          );
        },
      },
      {
        header: t('requested-credit'),
        accessorKey: 'requested_amount',
        cell: ({ row }) => {
          const request = row.original;
          const prefix =
            request.currency_type !== undefined && request.currency_type !== null ? request.currency_type.slug : 'R$';
          const amount = request.requested_amount || 0;
          const formatedAmount = amount ? Formatter.money(amount, 2, prefix, true) : '';
          return formatedAmount;
        },
      },
      {
        header: t('responsible'),
        accessorKey: 'user',
        cell: ({ row }) => {
          const request = row.original;
          return request.user?.name;
        },
      },
      {
        header: t('request-date'),
        accessorKey: 'created_at',
        cell: ({ row }) => {
          const request = row.original;
          const credtedAt = request.created_at ? Formatter.datetime(request.created_at) : '-';
          return credtedAt;
        },
      },
      {
        header: t('actions'),
        accessorKey: 'actions',
        cell: ({ row }) => <span className="flex">{getActions(row.original)}</span>,
        enableSorting: false,
      },
    ],
    [],
  );

  useEffect(() => {
    if (teamStatusHasChanged !== null) {
      getCreditRequestsQuery.refetch();
      setTeamStatusHasChanged(false);
    }
  }, [paginationConfig.pageSize, teamStatusHasChanged]);

  return (
    <div className="flex flex-col gap-2 overflow-hidden h-full">
      <DataTable
        columns={columns}
        fixedHeader
        data={getCreditRequestsQuery?.data?.data ?? []}
        withPagination
        onPaginationChange={newPagination => {
          dispatchPagination({
            payload: newPagination.pageSize,
            type: 'SET_PER_PAGE',
          });
          dispatchPagination({
            payload: newPagination.pageIndex,
            type: 'SET_CURRENT',
          });
        }}
        state={{
          pagination: {
            pageSize: paginationConfig.pageSize,
            pageIndex: paginationConfig.current,
          },
        }}
        onSortingChange={newSort => {
          if (typeof newSort === 'function') {
            const newSorting = newSort(
              paginationConfig.sort.map(sort => ({ id: sort.field, desc: sort.order === 'desc' })),
            );
            dispatchPagination({
              payload: newSorting.map(sort => ({
                field: sort.id,
                order: sort.desc ? 'desc' : 'asc',
              })),
              type: 'SET_SORT',
            });
          }
        }}
        isLoading={getCreditRequestsQuery?.isLoading}
        isFetching={getCreditRequestsQuery?.isFetching}
        pageCount={getCreditRequestsQuery?.data?.last_page ?? 0}
        defaultColumn={{
          enableSorting: true,
        }}
      />

      <Can I="credit.request.report.index">
        <Can I="credit.request.report.store">
          <ReportModal
            translation={getReportModalTranslation(t)}
            withDateFilter={false}
            width="50%"
            visibility={exporting}
            afterClose={() => setExporting(false)}
            listReportApi={creditApi.requests.listReport}
            generateReportApi={creditApi.requests.generateReport}
            downloadReportApi={documentApi.download}
            reportApiId={creditReportId}
            extraInputs
          />
        </Can>
      </Can>
    </div>
  );
};

export default ListView;
