import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Row, Col, Pagination } from 'antd';
import { Button, Spin } from 'components';
import { ShouldRender } from 'components/ShouldRender';
import { GuaranteeStatus } from 'configs/constants';
import { Can } from 'hooks/ability';
import { useAuth } from 'hooks/auth';
import { useGuarantees } from 'hooks/fetch/useGuarantees';
import { useModal } from 'hooks/modals/useModal';
import { usePage } from 'hooks/page';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';
import creditApi from 'services/api/credit';
import { formatValuesToFormData } from 'services/form';

import { GuaranteesResumeData } from '../../../../../@types/data/GuaranteesResumeData';
import PaginationData from '../../../../../@types/data/PaginationData';
import { GuaranteeData } from '../../../../../types/CreditRequest/GuaranteeData';
import { GuaranteeApprovalForm } from './GuaranteeApprovalForm/GuaranteeApprovalForm';
import { GuaranteeApprovalModal } from './GuaranteeApprovalModal/GuaranteeApprovalModal';
import { GuaranteeForm } from './GuaranteeForm/GuaranteeForm';
import { GuaranteesList } from './GuaranteesList/GuaranteesList';
import { GuaranteesResume } from './GuaranteesResume/GuaranteesResume';
import { NewGuaranteeModal } from './NewGuaranteeModal/NewGuaranteeModal';
import { Container } from './styles';
import { GuaranteesProps } from './types';

const Guarantees: React.FC<GuaranteesProps> = ({
  isEditable = true,
  canCurrentUserApproveGuarantee,
  limitRules,
  creditRequestId,
}) => {
  const newGuaranteeModal = useModal();
  const guaranteeApprovalModal = useModal();
  const {
    getGuaranteesStatuses: fetchGuaranteesStatuses,
    getGuaranteesByCreditRequestId,
    isFetchingGuaranteesByCreditRequestId,
    getGuaranteesResume,
    isFetchingGuaranteesResume,
  } = useGuarantees();

  const [limitTotal, setLimitTotal] = useState(0);
  const [limitTotalExisting] = useState(0);
  const [limitTotalNew] = useState(0);
  const [loading, setLoading] = useState(false);
  // const [partialRemoving, setPartialRemoving] = useState(null);
  const [formLimit] = Form.useForm();
  const [formLimitApproval] = Form.useForm();
  const { t } = useTranslation();
  const { alertStatus } = usePage();
  const { user } = useAuth();
  const [partialLimitDataApproval, setPartialLimitDataApproval] = useState<{
    user_id: string;
    credit_request_partial_limit_id: string;
    credit_request_partial_limit_status_id: string;
    comment: string;
  }>({ user_id: '', credit_request_partial_limit_id: '', credit_request_partial_limit_status_id: '', comment: '' });
  const [paginatedGuarantees, setPaginatedGuarantees] = useState<PaginationData<GuaranteeData> | undefined>(undefined);
  const [guaranteesResume, setGuaranteesResume] = useState<GuaranteesResumeData | null>(null);

  const setPartialLimitDataExisting = useState([])[1];

  const pageStatus = useCreditRequestStore(state => state.pageStatus);
  const creditRequestData = useCreditRequestStore(state => state.creditRequestData);

  const newGuaranteeButtonCondition =
    isEditable &&
    limitRules &&
    (!!creditRequestData?.approved_value ||
      !!creditRequestData?.approved_value_cash ||
      !!creditRequestData?.approved_value_barter);

  const handleNewGuarantee = () => {
    newGuaranteeModal.open();
    // formLimit.setFieldsValue({
    //   approved_value: approvedValue,
    //   limitTotal: limitTotal || 0,
    //   available_value: (approvedValue - limitTotal).toFixed(2),
    // });
  };

  const handleSubmitGuaranteeForm = () => {
    formLimit.validateFields().then(async () => {
      newGuaranteeModal.close();
      setLoading(true);
      const formValues = formLimit.getFieldsValue();
      let formData = new FormData();
      formData = formatValuesToFormData(formValues, formData, [], ['documents']);

      formValues.documents?.forEach((document: any, index: number) => {
        formData.append(`documents[${index}][file]`, document);
        formData.append(`documents[${index}][name]`, document.name);
      });
      formData.append(`user_id`, user.id);
      creditApi.partialLimit
        .store(formData, creditRequestId)
        .then(() => {
          fetchGuarantees(paginatedGuarantees.current_page);
          fetchGuaranteesResume();
          // setPartialLimitData([...partialLimitData, res.data]);
        })
        .catch((err: any) => {
          alertStatus(err, 'error');
        })
        .finally(() => {
          formLimit.resetFields();
          setLoading(false);
        });
    });
  };

  const handleSubmitGuaranteeApprovalForm = () => {
    setLoading(true);
    formLimitApproval.validateFields().then(async () => {
      guaranteeApprovalModal.close();
      const formValues = formLimitApproval.getFieldsValue();
      let formData = new FormData();
      formData = formatValuesToFormData(formValues, formData, [], ['documents']);

      formValues.documents?.forEach((document: any, index: number) => {
        formData.append(`documents[${index}][file]`, document);
        formData.append(`documents[${index}][name]`, document.name);
      });

      formData.append(`user_id`, user.id);
      formData.append(
        `credit_request_partial_limit_status_id`,
        partialLimitDataApproval.credit_request_partial_limit_status_id,
      );
      formData.append(`credit_request_partial_limit_id`, partialLimitDataApproval.credit_request_partial_limit_id);
      formData.append(`comment`, formValues.comment_approval);

      const request = (() => {
        if (partialLimitDataApproval.credit_request_partial_limit_status_id === GuaranteeStatus.APPROVED) {
          return creditApi.partialLimitApproval.approval;
        }
        if (partialLimitDataApproval.credit_request_partial_limit_status_id === GuaranteeStatus.REPROVED) {
          return creditApi.partialLimitApproval.disapproved;
        }
        if (partialLimitDataApproval.credit_request_partial_limit_status_id === GuaranteeStatus.VALIDATED) {
          return creditApi.partialLimitApproval.validated;
        }
        if (partialLimitDataApproval.credit_request_partial_limit_status_id === GuaranteeStatus.REJECTED) {
          return creditApi.partialLimitApproval.refused;
        }
      })();

      request(formData, partialLimitDataApproval.credit_request_partial_limit_id)
        .then(() => {
          fetchGuarantees(paginatedGuarantees?.current_page ?? 1);
          fetchGuaranteesResume();
          // setPartialLimitData(
          //   partialLimitData.map((item: any) => (item.id === res.data.id ? { ...item, ...res.data } : item)),
          // );
        })
        .catch((err: any) => {
          alertStatus(err, 'error');
        })
        .finally(() => {
          formLimitApproval.resetFields();
          setLoading(false);
        });
    });
  };

  // // const handleOpenApprovalModal = async () => {
  // //   if (!record.approval?.length) {
  // //   	setPartialLimitDataApproval(record);
  // //   	formLimitApproval.setFieldsValue({
  // //   		comment: record.comment,
  // //   		id: record.id,
  // //   		user: record.user.name,
  // //   		created_at: moment(record.created_at).format(appConfigs.formatDate),
  // //   		value: record.value,
  // //   	});
  // //   guaranteeApprovalModal.open();
  // // };

  const handleOpenValidationModal = (guarantee: GuaranteeData, type: GuaranteeStatus) => {
    guaranteeApprovalModal.open();
    setPartialLimitDataApproval({
      ...guarantee,
      user_id: user.id,
      credit_request_partial_limit_id: guarantee.id,
      credit_request_partial_limit_status_id: type,
      comment: '',
    });
  };

  const handleBeforeCloseNewGuaranteeModal = () => {
    formLimit.resetFields();
  };

  const handleBeforeCloseGuaranteeApprovalModal = () => {
    formLimit.resetFields();
  };

  const fetchGuarantees = async (pageNumber = 1) => {
    if (!creditRequestId) return;
    const response = await getGuaranteesByCreditRequestId(creditRequestId, {
      params: { page: pageNumber, per_page: 10 },
    });
    setPartialLimitDataExisting(response.data);
    setPaginatedGuarantees(response);
  };

  const handleChangePage = (pageNumber: number) => fetchGuarantees(pageNumber);

  const fetchGuaranteesResume = async () => {
    if (!creditRequestId) return;
    const response = await getGuaranteesResume(creditRequestId);
    if (response) setGuaranteesResume(response);
  };

  useEffect(() => {
    if (!pageStatus.new) {
      fetchGuaranteesStatuses();
      fetchGuarantees();
      fetchGuaranteesResume();
    }
    // eslint-disable-next-line
  }, [pageStatus.new]);

  useEffect(() => {
    setLimitTotal(limitTotalExisting + limitTotalNew);
    // eslint-disable-next-line
  }, [limitTotalExisting, limitTotalNew]);

  return (
    <Container>
      <Spin spinning={loading}>
        <ShouldRender condition={newGuaranteeButtonCondition}>
          <Button
            style={{ float: 'right' }}
            status="primary-outline"
            onClick={handleNewGuarantee}
            permission="credit.request.partial.limit.store"
          >
            {t('pages.credit-request.modal-limit.buttonAdd')}
          </Button>
        </ShouldRender>
        <div className="group-form" style={{ width: '100%' }}>
          <Row>
            <Spin spinning={isFetchingGuaranteesResume}>
              <GuaranteesResume.Root>
                <GuaranteesResume.GuaranteeValueType isVisible={!!guaranteesResume?.value}>
                  <GuaranteesResume.GuaranteeRequestedValue value={Number(guaranteesResume?.value)} />
                  <GuaranteesResume.GuaranteeApprovedValue value={Number(guaranteesResume?.value_approved_warranty)} />
                  <GuaranteesResume.GuaranteeOpenValue value={Number(guaranteesResume?.value_opened_warranty)} />
                  <GuaranteesResume.GuaranteeDifferenceValue value={Number(guaranteesResume?.value_difference)} />
                </GuaranteesResume.GuaranteeValueType>

                <GuaranteesResume.GuaranteeValueType isVisible={!!guaranteesResume?.value_cash}>
                  <GuaranteesResume.GuaranteeRequestedCashValue value={Number(guaranteesResume?.value_cash)} />
                  <GuaranteesResume.GuaranteeApprovedCashValue
                    value={Number(guaranteesResume?.value_approved_cash_warranty)}
                  />
                  <GuaranteesResume.GuaranteeOpenCashValue
                    value={Number(guaranteesResume?.value_opened_cash_warranty)}
                  />
                  <GuaranteesResume.GuaranteeDifferenceCashValue
                    value={Number(guaranteesResume?.value_difference_cash)}
                  />
                </GuaranteesResume.GuaranteeValueType>

                <GuaranteesResume.GuaranteeValueType isVisible={!!guaranteesResume?.value_barter}>
                  <GuaranteesResume.GuaranteeRequestedBarterValue value={Number(guaranteesResume?.value_barter)} />
                  <GuaranteesResume.GuaranteeApprovedBarterValue
                    value={Number(guaranteesResume?.value_approved_barter_warranty)}
                  />
                  <GuaranteesResume.GuaranteeOpenBarterValue
                    value={Number(guaranteesResume?.value_opened_barter_warranty)}
                  />
                  <GuaranteesResume.GuaranteeDifferenceBarterValue
                    value={Number(guaranteesResume?.value_difference_barter)}
                  />
                </GuaranteesResume.GuaranteeValueType>
              </GuaranteesResume.Root>
            </Spin>
            <Col span={4}>
              <Can I="credit.request.partial.limit.update">
                <NewGuaranteeModal
                  close={newGuaranteeModal.close}
                  onSubmit={handleSubmitGuaranteeForm}
                  beforeClose={handleBeforeCloseNewGuaranteeModal}
                  isVisible={newGuaranteeModal.isOpen}
                  isSubmitDisabled={false}
                >
                  <GuaranteeForm
                    form={formLimit}
                    limitTotal={limitTotal}
                    differences={{
                      differenceValue: Number(guaranteesResume?.value_difference),
                      differenceCash: Number(guaranteesResume?.value_difference_cash),
                      differenceBarter: Number(guaranteesResume?.value_difference_barter),
                    }}
                  />
                </NewGuaranteeModal>
              </Can>

              <GuaranteeApprovalModal
                close={guaranteeApprovalModal.close}
                isVisible={guaranteeApprovalModal.isOpen}
                beforeClose={handleBeforeCloseGuaranteeApprovalModal}
                onSubmit={handleSubmitGuaranteeApprovalForm}
                statusId={partialLimitDataApproval.credit_request_partial_limit_status_id}
              >
                <GuaranteeApprovalForm
                  form={formLimitApproval}
                  guaranteeData={partialLimitDataApproval}
                  statusId={partialLimitDataApproval.credit_request_partial_limit_status_id}
                />
              </GuaranteeApprovalModal>
            </Col>
          </Row>

          <Spin spinning={isFetchingGuaranteesByCreditRequestId}>
            <GuaranteesList
              datasource={creditRequestData?.datasource}
              guarantees={paginatedGuarantees?.data ?? []}
              canApprove={!!canCurrentUserApproveGuarantee}
              openValidationModal={handleOpenValidationModal}
              requestSapStatus={creditRequestData?.last_import?.description}
              refetchList={() => {
                fetchGuaranteesResume();
                fetchGuarantees(paginatedGuarantees?.current_page ?? 1);
              }}
            />
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '10px' }}>
              <Pagination
                defaultCurrent={1}
                total={paginatedGuarantees?.total ?? 0}
                pageSize={10}
                onChange={handleChangePage}
                disabled={!paginatedGuarantees?.data?.length}
              />
            </div>
          </Spin>
        </div>
      </Spin>
    </Container>
  );
};

export default Guarantees;
