import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col } from 'antd';
import { FormItem, Input } from 'components';
import { ThirdProviderEnum } from 'types/CreditRequest/enums/ThirdProvidersEnum';

import { RenderFormField } from '../RenderFormField';

interface Props {
  handleChangeProfile: (value: any) => void;
  handleFindProvider: () => Promise<void>;
  typePerson: string;
  statusReadOnly: boolean;
  disabledProvider: boolean;
  isFormVisible: boolean;
  sustainabilityId: any;
  docRef: React.MutableRefObject<HTMLInputElement | undefined>;
  providerRef: React.MutableRefObject<HTMLInputElement | undefined>;
  thirdProviders: ThirdProviderEnum[];
}

export const ProspectionForm = ({
  handleChangeProfile,
  statusReadOnly,
  typePerson,
  handleFindProvider,
  disabledProvider,
  isFormVisible,
  sustainabilityId,
  docRef,
  providerRef,
  thirdProviders,
}: Props) => {
  const { t } = useTranslation();

  const shouldShowDatasul = thirdProviders?.some(provider => provider.toLowerCase() === 'datasul');
  const shouldShowSap = thirdProviders?.some(provider => provider.toLowerCase() === 'sap');

  return isFormVisible ? (
    <>
      <RenderFormField
        fieldName="profile"
        onChange={handleChangeProfile}
        isDisabled={statusReadOnly || sustainabilityId}
      />
      <RenderFormField
        fieldName="docNumber"
        onBlur={handleFindProvider}
        isDisabled={statusReadOnly || sustainabilityId}
        typePerson={typePerson}
        ref={docRef}
      />

      <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
        <FormItem label={t('pages.sustainability.form.name')} name="provider" rules={[{ required: true }]}>
          <Input ref={providerRef} disabled={disabledProvider || statusReadOnly || sustainabilityId} maxLength={255} />
        </FormItem>
      </Col>
      <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} style={{ display: shouldShowDatasul ? '' : 'none' }}>
        <FormItem
          label={t('pages.sustainability.form.code_datasul')}
          name="code_datasul"
          style={{ display: shouldShowDatasul ? '' : 'none' }}
        >
          <Input disabled maxLength={255} />
        </FormItem>
      </Col>
      <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8} style={{ display: shouldShowSap ? '' : 'none' }}>
        <FormItem
          label={t('pages.sustainability.form.cod_sap')}
          name="cod_sap"
          style={{ display: shouldShowSap ? '' : 'none' }}
        >
          <Input disabled maxLength={255} />
        </FormItem>
      </Col>
      <RenderFormField fieldName="harvest" isDisabled={statusReadOnly || sustainabilityId} />
      <RenderFormField fieldName="commodity" isDisabled={statusReadOnly || sustainabilityId} />
    </>
  ) : null;
};
