import { ColumnType } from 'antd/lib/table';

export const columns = [
  {
    dataIndex: 'name',
    key: 'name',
  },
  {
    dataIndex: 'user',
    key: 'user',
  },
  {
    dataIndex: 'created_at',
    key: 'created_at',
  },
  {
    dataIndex: 'status',
    key: 'status',
  },
  {
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    fixed: 'right',
    width: '150px',
  },
] as ColumnType<object>[];

export default columns;
