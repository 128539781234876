import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import DTOErrorReponse from '../../../../../@types/dtos/DTOErrorReponse';
import { Input, Button, FormItem, Select } from '../../../../../components';
import { useCache } from '../../../../../hooks/cache';
import { ItemsType, usePage } from '../../../../../hooks/page';
import sustainabilityApi from '../../../../../services/api/sustainability';
import { SFilterContainer } from './styles';
import { FilterProps } from './types';

export const Filter = ({ handleFastSearch }: FilterProps) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const { alertStatus } = usePage();
  const { getCreditOptions, current_harvest } = useCache();

  const tableDataClearAllFilters = () => {
    form.resetFields();
    handleFastSearch();
  };

  const handleFilter = () => {
    const values = form.getFieldsValue();
    if (values.parent !== 'all') {
      values.parent = values.parent === 'parent';
    }
    handleFastSearch(values);
  };

  // sustainabilitTypes
  const [sustainabilitTypes, setSustainabilitTypes] = useState<ItemsType[]>(() => {
    let sustainabilitTypesOptions: ItemsType[] = [];
    sustainabilityApi.reporttype
      .get()
      .then((response: any) => {
        const { data: sustainabilitTypeData } = response.data;
        sustainabilitTypesOptions = sustainabilitTypeData.map((sustainabilitType: { id: any; name: any }) => ({
          key: sustainabilitType.id,
          value: sustainabilitType.id,
          label: sustainabilitType.name,
        }));
        setSustainabilitTypes(sustainabilitTypesOptions);
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
    return sustainabilitTypesOptions;
  });

  // sustainabilitTypes
  const [status, setStatus] = useState<ItemsType[]>(() => {
    let statusOptions: ItemsType[] = [];
    sustainabilityApi.reportstatus
      .get()
      .then((response: any) => {
        const { data: statusData } = response.data;
        statusOptions = statusData.map((statusRow: { id: any; name: any }) => ({
          key: statusRow.id,
          value: statusRow.id,
          label: statusRow.name,
        }));
        setStatus(statusOptions);
      })
      .catch((err: DTOErrorReponse) => alertStatus(err, 'error'));
    return statusOptions;
  });

  return (
    <SFilterContainer>
      <Form form={form} name="filter-owner" className="form-secondary form-filters grid-filters">
        <div className="filter-search">
          <FormItem name="search" label={t('pages.sustainability.search')}>
            <Input placeholder={t(`pages.sustainability.search`)} />
          </FormItem>
        </div>
        <div className="filter-search2">
          <FormItem name="harvest" label={t('pages.sustainability.filters.harvest')} initialValue={[current_harvest]}>
            <Select options={getCreditOptions('harvest')} />
          </FormItem>
        </div>
        <div className="filter-search3">
          <FormItem name="type" label={t('pages.sustainability.form.type')}>
            <Select mode="multiple" options={sustainabilitTypes} />
          </FormItem>
        </div>
        <div className="filter-search4">
          <FormItem name="status" label={t('pages.sustainability.table.status')}>
            <Select mode="multiple" options={status} />
          </FormItem>
        </div>
        <div className="filter-button">
          <FormItem label="" className="form-item-without-label">
            <Button status="primary" text="uppercase" htmlType="submit" onClick={handleFilter}>
              <FaSearch /> {t('form.actions.search')}
            </Button>
          </FormItem>
        </div>
        <div className="filter-clear">
          <Button status="secondary" size="middle" onClick={tableDataClearAllFilters}>
            {t('pages.edocuments.contracttypes.buttonClearFilter')}
          </Button>
        </div>
      </Form>
    </SFilterContainer>
  );
};
