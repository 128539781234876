import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAlertDialog } from 'components/ui/alert-dialog';
import { Form } from 'components/ui/form';
import { FormItem } from 'components/ui/form-item';
import { Input } from 'components/ui/input';
import {
  DIVISION_SOFT_ID,
  LEGAL_PERSON_TYPE,
  OPERATION_MTM_ID,
  OPERATION_PREFIN_ID,
  PHYSICAL_PERSON_TYPE,
  SUBDIVISION_SUGAR_TRADING_ID,
} from 'configs/constants';
import { useCreditRequestContext } from 'context/CreditRequestContext';
import exceptionHandler from 'services/exceptions';
import DTODatasulProvider from 'types/Datasul/DTODatasulProvider';

import { useFindRegisteredCustomerMutation } from '../api/use-find-registered-customer-mutation';
import { useStoreRegisteredCustomerMutation } from '../api/use-store-registered-customer-mutation';
import { useGetProviderRequest } from '../rules/general/useGetProviderRequest';
import { useCreditRequestStore } from '../store/useCreditRequestStore';
import { useFillProviderData } from './useFillProviderData';

type FindProviderProps = {
  personType?: 'legal' | 'physical';
} | null;

export const useFindProvider = (props?: FindProviderProps) => {
  const { getProviderRequest } = useGetProviderRequest();
  const { fillProviderData } = useFillProviderData();
  const { creditForm } = useCreditRequestContext();
  const { alert } = useAlertDialog();
  const { t } = useTranslation();
  const form = useForm({
    defaultValues: { name: '' },
  });

  const selectedOperationId = useCreditRequestStore(state => state.selectedOperationId);
  const selectedDivisionId = useCreditRequestStore(state => state.selectedDivisionId);
  const selectedSubDivisionId = useCreditRequestStore(state => state.selectedSubDivisionId);
  const thirdProviders = useCreditRequestStore(state => state.thirdProviders);
  const setIsLoading = useCreditRequestStore(state => state.setIsLoading);
  const setSelectedPersonId = useCreditRequestStore(state => state.setSelectedPersonId);
  const setSelectedProviderOption = useCreditRequestStore(state => state.setSelectedProviderOption);
  const setSelectedPersonType = useCreditRequestStore(state => state.setSelectedPersonType);
  const setPeople = useCreditRequestStore(state => state.setPeople);
  const setIsBlocking = useCreditRequestStore(state => state.setIsBlocking);

  const storeRegisteredCustomerMutation = useStoreRegisteredCustomerMutation({
    personType: props?.personType,
  });
  const findRegisteredCustomerMutation = useFindRegisteredCustomerMutation({
    personType: props?.personType,
  });

  const findProvider = useCallback(async () => {
    const formValues = creditForm.getFieldsValue();
    let document = '';
    if (!formValues['requester_person.document']) return;
    document = formValues['requester_person.document'].replace(/\D/g, '');

    if (
      selectedDivisionId === DIVISION_SOFT_ID &&
      selectedSubDivisionId === SUBDIVISION_SUGAR_TRADING_ID &&
      (selectedOperationId === OPERATION_MTM_ID || selectedOperationId === OPERATION_PREFIN_ID)
    ) {
      const response = await findRegisteredCustomerMutation.mutateAsync(document);
      const foundPerson = response?.data?.[0];
      if (!foundPerson)
        return alert({
          type: 'warning',
          title: t('provider-not-found-prefin-register'),
          description: (
            <div className="flex flex-col gap-2">
              <span>{t('would-you-like-to-create-manually')}</span>
              <Form onSubmit={() => console.log('')} form={form}>
                <FormItem
                  label={t('provider-name')}
                  name="name"
                  rules={{
                    required: true,
                  }}
                >
                  <Input maxLength={255} />
                </FormItem>
              </Form>
            </div>
          ),
          onCancel: () => {
            form.reset();
          },
          onOk: async () => {
            await form.handleSubmit(async values => {
              const response = await storeRegisteredCustomerMutation.mutateAsync({
                name: props?.personType === 'physical' ? values.name : undefined,
                company_name: props?.personType === 'legal' ? values.name : undefined,
                cpf: props?.personType === 'physical' ? formValues['requester_person.document'] : undefined,
                cnpj: props?.personType === 'legal' ? formValues['requester_person.document'] : undefined,
              });
              creditForm.setFieldsValue({
                'requester_person.name': values.name,
                requester_person_id: response.id,
              });
              form.reset();
            })();
          },
          okText: t('yes'),
          cancelText: t('no'),
        });
      return creditForm.setFieldsValue({
        requester_person_name: props?.personType === 'legal' ? foundPerson.company_name : foundPerson.name,
        'requester_person.name': props?.personType === 'legal' ? foundPerson.company_name : foundPerson.name,
        requester_person_id: foundPerson.id,
      });
    }
    setIsLoading(true);

    const request = getProviderRequest(document);

    await request
      .then((response: { data: DTODatasulProvider }) => {
        const data: DTODatasulProvider = response.data as DTODatasulProvider;
        const person_id = data.legal_person_id
          ? data.legal_person_id
          : data.physical_person_id
          ? data.physical_person_id
          : undefined;

        const personType = data.legal_person_id ? LEGAL_PERSON_TYPE : PHYSICAL_PERSON_TYPE;
        setSelectedPersonId(person_id);
        setSelectedProviderOption({
          label: data.name ?? data.company_name ?? '',
          value: person_id ?? '',
          key: person_id,
        });

        setSelectedPersonType(personType);
        setPeople([]);
        setIsBlocking(true);

        fillProviderData(data, person_id);
      })
      .catch((err: any) => {
        exceptionHandler(err);
        creditForm.setFieldsValue({
          'requester_person.json_data.code_erp': '',
          sap_bp_headoffice_subsidiary_code: '',
          requester_person_id: '',
          requester_person_name: '',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, [selectedSubDivisionId, selectedOperationId, thirdProviders]);

  return {
    findProvider,
  };
};
