import { ReportModalTranslation } from './types';

export const defaultTranslation = {
  closeModal: 'Close',
  newReport: 'New',
  send: 'Send',
  status: { active: 'active' },
  buttons: {
    download: 'Download',
  },
  headers: {
    user: 'User',
    code: 'Code',
  },
  filters: {
    date: 'Date',
  },
  columns: {
    fileName: 'File Name',
    userName: 'User Name',
    date: 'Date',
    status: 'Status',
    options: 'Options',
  },
  table: {
    pagination: {
      totalOfRegisters: 'Total of registers',
    },
  },
} as ReportModalTranslation;
