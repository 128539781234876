import React from 'react';
import { useTranslation } from 'react-i18next';

import { message } from 'antd';
import { FormItem, IconWithTooltip, Input } from 'components';
import { Can } from 'hooks/ability';
import { useModalMessage } from 'hooks/helpers/useModalMessage';
import { useModal } from 'hooks/modals/useModal';
import { IntegrationsModal } from 'pages/Contracts/Emission/modules/ContractStages/ContractDetails/IntegrationsModal';
import { useFindContract } from 'pages/CreditRequest/hooks/useFindContract';
import { useCreditRequestStore } from 'pages/CreditRequest/store/useCreditRequestStore';
import datasulApi from 'services/api/datasul';

export const ContractNumberInput = () => {
  const { t } = useTranslation();
  const { open, isOpen, close } = useModal();
  const { handleSuccess } = useModalMessage();
  const { findContract } = useFindContract();

  const selectedContractId = useCreditRequestStore(state => state.selectedContractId);
  const thirdProviders = useCreditRequestStore(state => state.thirdProviders);
  const pageStatus = useCreditRequestStore(state => state.pageStatus);

  const forceContractUpdate = async () => {
    const hide = message.loading(t('updating'));
    const contractId = selectedContractId;
    const response = await datasulApi.contractV2.forceContractById(contractId);
    if (response.data) handleSuccess(t('pages.edocuments.refreshSuccess'));
    setTimeout(hide, 0);
  };

  return (
    <>
      <FormItem
        label={t('pages.credit-request.form.json_data_contract_number')}
        name="json_data.contract_number"
        rules={[{ required: true }]}
        permission={['credit.request.contract.volume']}
        needAllPermissions
      >
        <Input
          disabled={pageStatus.viewing}
          placeholder={t('pages.credit-request.form.contract_number_placeholder')}
          suffix={
            <>
              <IconWithTooltip
                action="search"
                className="icon-in-input-suffix"
                title={t('pages.credit-request.form.contract-search')}
                onClick={() => findContract()}
              />
              {selectedContractId ? (
                <IconWithTooltip
                  action="detail"
                  className="icon-in-input-suffix"
                  title={t('pages.credit-request.form.contract-json')}
                  onClick={open}
                />
              ) : null}

              {thirdProviders.includes('datasul') ? (
                <Can I="contract.forceupdate">
                  <IconWithTooltip
                    action="refresh"
                    className="icon-in-input-suffix"
                    title={t('pages.edocuments.emission.contractDetails.refreshContract')}
                    onClick={forceContractUpdate}
                  />
                </Can>
              ) : null}
            </>
          }
        />
      </FormItem>
      <IntegrationsModal close={close} isVisible={isOpen} isDisabled={false} contractId={selectedContractId} />
    </>
  );
};
